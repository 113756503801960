import React from "react"

const footerStyle = {
  textAlign: "center",
}

const Footer = () => (
  <footer style={footerStyle}>
    <small> Built with a lot of </small>
    <span role="img" aria-label="coffee">
      ☕
    </span>
    <div>
      <small>© {new Date().getFullYear()}</small>
    </div>
  </footer>
)
export default Footer

import React from "react"
import { Link } from "gatsby"
import { rhythm } from "../../../utils/typography"
import { theme } from "../../../utils/typography"

const postHeaderStyles = {
  containerStyle: {
    marginLeft: `auto`,
    marginRight: `auto`,
    maxWidth: rhythm(24),
    padding: `${rhythm(1 + 3 / 4)} ${rhythm(3 / 4)}`,
  },
  linkStyle: {
    boxShadow: `none`,
    fontFamily: theme.headerFontFamily,
    fontWeight: 100,
    color: "hsl(217, 71%, 53%)",
  },
}

const PostsHeader = ({ title }) => {
  const { containerStyle, linkStyle } = postHeaderStyles
  return (
    <div style={containerStyle}>
      <Link to="/">
        <h1 style={linkStyle}>{title}</h1>
      </Link>
    </div>
  )
}

export default PostsHeader

import React from "react"
import { Link } from "gatsby"
import { theme } from "../../../utils/typography"

const postHeaderStyles = {
  containerStyle: {
    fontFamily: theme.headerFontFamily,
    fontWeight: 100,
    marginTop: 0,
  },
  linkStyle: {
    boxShadow: `none`,
    color: `inherit`,
  },
}

const PostHeader = ({ title }) => {
  const { containerStyle, linkStyle } = postHeaderStyles
  return (
    <h3 style={containerStyle}>
      <Link style={linkStyle} to={`/`}>
        {title}
      </Link>
    </h3>
  )
}

export default PostHeader

import React from "react"
import PostsHeader from "./headers/posts"
import PostHeader from "./headers/post"
import Footer from "./footer"
import { rhythm } from "../../utils/typography"

const rootPath = `${__PATH_PREFIX__}/`

const layoutStyle = {
  marginLeft: `auto`,
  marginRight: `auto`,
  textAlign: "justify",
  maxWidth: rhythm(24),
  padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
}

const Layout = ({ location, title, children }) => {
  const layoutTitle = title ? title : 'Gabrijel Boduljak'
  const header =
    location.pathname === rootPath ? (
      <PostsHeader title={layoutTitle} />
    ) : (
      <PostHeader title={layoutTitle} />
    )

  return (
    <div style={layoutStyle}>
      <header>{header}</header>
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout

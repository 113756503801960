import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"

const Theme = Wordpress2016

delete Theme.googleFonts
Theme.headerFontFamily = ["Dosis", "Helvetica Neue", "Helvetica", "sans-serif"]
Theme.bodyFontFamily = ["Source Sans Pro", "Helvetica", "Arial"]
Theme.accentColor = "hsl(217, 71%, 53%)"

Theme.overrideThemeStyles = ({ scale }) => {
  return {
    a: {
      color: Theme.accentColor,
    },
    "section > h2,h3,h4,h5,h6,h7": {
      fontWeight: "200",
    },
    "blockquote > p": {
      fontWeight: "300",
      fontFamily: Theme.headerFontFamily.join(""),
    },
    "header a > h1": {
      ...scale(1.25),
      textAlign: "center",
    },
    "article > header > h1": {
      color: Theme.accentColor,
    },
    "article > header > h2": {
      color: Theme.accentColor,
    },
    "article small a": {
      color: "black",
      textDecoration: "none",
      boxShadow: "none",
    },
    "article small a:hover": {
      boxShadow: "0 1px 0 0 currentColor",
    },
    "section ol li a": {
      color: "black",
      textDecoration: "none",
      boxShadow: "none",
    },
    "section ol li a:hover": {
      boxShadow: "0 1px 0 0 currentColor",
    },
    li: {
      listStylePosition: "inside",
      textIndent: "0.5em",
    },
  }
}

const typography = new Typography(Theme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography

export const rhythm = typography.rhythm
export const scale = typography.scale
export const theme = Theme
